import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';

import Button from '../../atoms/button';
import PauseSVG from '../../atoms/icon-pause';
import PlaySVG from '../../atoms/icon-play';
import { logAmplitude } from '../../utils/amplitude';

const StatusWrapper = styled.div`
  pointer-events: none;
  bottom: 0;
  align-items: center;
`;

const Promo = styled.aside`
  align-items: center;
  background: ${(props) => props.theme.color.brand};
  color: ${(props) => props.theme.color.white};
  display: flex;
  flex-direction: column;
  font-family: ${(props) => props.theme.font.sans};
  font-size: ${(props) => props.theme.modularScale.sixteen};
  justify-content: center;
  line-height: 1.8rem;
  min-height: 6.4rem;
  padding: .8rem 2rem;
  width: 100%;
  text-align: center;
  top: 0;
  z-index: ${(props) => props.theme.layers.audience + 11};

  .mobile-hidden {
    display: none;
  }

  ${StatusWrapper} {
    display: none;
  }

  :hover {
    ${StatusWrapper} {
      display: flex;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoint.small}) {
    .mobile-visible {
      display: none;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoint.medium}) {
    :focus-within {
      ${StatusWrapper} {
        display: flex;
      }
    }
  }
`;

const PromoList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0px;
  width: 100%;
`;

const PromoListActive = (props) => css`
  max-height: 200px;
  opacity: 1;
  pointer-events: all;
  transition-duration: ${props.theme.animation.default};
  transition-property: opacity;
  transition-timing-function: ${props.theme.animation.easeInQuad};
`;

const PromoListItem = styled.li`
  max-height: 0;
  opacity: 0;
  pointer-events: none;

  a {
    color: inherit;
    text-decoration: none;
  }

  strong {
    font-weight: 700;
  }

  ${(props) => (props.isActive ? PromoListActive : '')}
`;

const PlayButton = styled(Button)`
  background: transparent;
  border: none;
  color: ${(props) => props.theme.color.white};
  z-index: ${(props) => props.theme.layers.upstage};
  padding: 0;
  height: 2rem;
  width: 2rem;
  display: flex;
  pointer-events: all;

  svg {
    width: 2rem;
    height: 2rem;
    fill: ${(props) => props.theme.color.white};
  }
`;

const ProgressBar = styled.progress`
  -webkit-appearance: none;
  width: 20rem;
  height: 0.5rem;
  border-radius: 0.5rem;
  background-color: hsla(0, 0%, 0%, 0.10);

  ::-webkit-progress-bar {
    width: 20rem;
    height: 0.5rem;
    border-radius: 0.5rem;
    background-color: hsla(0, 0%, 0%, 0.10);
  }

  ::-webkit-progress-value {
    border-radius: 0.5rem;
    background: ${({ theme }) => theme.color.brandLight};
    transition: width 200ms linear;
  }
`;

const ProgressLabel = styled.label`
  display: none;
`;

const PromoInner = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 50rem;
  width: 100%;
`;

const getLink = (target, currentTarget) => {
  if (target.nodeName.toLowerCase() === 'a') return target.href;
  const temp = target?.querySelector('a');

  return temp && currentTarget?.contains(temp)
    ? temp?.getAttribute('href')
    : null;
};

const handleOnClick = (e, position) => {
  const text = e.currentTarget.innerText;
  const link = getLink(e.target, e.currentTarget);

  logAmplitude('Clicked Blue Banner', {
    text,
    link,
    position: position + 1
  });
};

const PromoCopy = (props) => {
  const [active, setActive] = useState(props.activePromo);
  const [playing, setPlaying] = useState(true);
  const [btnTouched, setBtnTouched] = useState(false);
  const [progressValue, setProgressValue] = useState(0);

  // filter content by date
  let filteredContent;

  if (typeof props.content?.[0] === 'string') {
    filteredContent = props.content;
  } else {
    filteredContent = props.content?.filter((item) => {
      const now = new Date();
      const startsAt = new Date(item.starts_at);
      const endsAt = new Date(item.ends_at);
      return (item.ignore_dates || (now > startsAt && now < endsAt));
    }) ?? [];
  }

  useEffect(() => {
    const promoCarousel = () => {
      if (global.promoCarousel !== null || !playing) {
        clearInterval(global.promoCarousel);
        clearInterval(global.promoProgress);
      }

      let progVal = 0;

      global.promoProgress = setInterval(() => {
        if (playing) {
          if (progVal < 100) progVal += 25;
          else progVal = 0;
          setProgressValue(progVal);
        }
      }, +props.interval / 5);

      global.promoCarousel = setInterval(() => {
        const activeP = filteredContent[active];

        if (playing && activeP && filteredContent.length > 1) {
          const next = active === filteredContent.length - 1
            ? 0
            : active + 1;
          setActive(next);
        }
      }, props.interval);
    };

    promoCarousel();

    return () => {
      clearInterval(global.promoCarousel);
      clearInterval(global.promoProgress);
    };
  }, [active, playing]);

  const handleTogglePlay = () => {
    setPlaying(!playing);
    if (!btnTouched) setBtnTouched(true);
  };

  const handlePlay = () => {
    if (!btnTouched) setPlaying(true);
  };

  const handlePause = () => {
    if (!btnTouched) setPlaying(false);
  };

  return (
    <Promo
      tabIndex="0"
      aria-label="promotion banner carousel"
      onMouseEnter={handlePause}
      onMouseLeave={handlePlay}
      isNavigationActive={props.isNavigationActive}
    >
      {
        filteredContent.length > 0 && (
          <>
            <PromoInner>
              <PromoList>
                {filteredContent.map((item, index) => (
                  <PromoListItem
                    key={`promo-item-${item.content}`}
                    isActive={active === index}
                    aria-hidden={active !== index}
                    dangerouslySetInnerHTML={{ __html: typeof item === 'string' ? item : item.content }}
                    onClick={(e) => handleOnClick(e, index)}
                  />
                ))}
              </PromoList>
            </PromoInner>

            <StatusWrapper>
              <PlayButton
                aria-label={playing ? 'pause motion' : 'resume motion'}
                onClick={handleTogglePlay}
              >
                {playing ? <PauseSVG /> : <PlaySVG />}
              </PlayButton>

              <ProgressLabel htmlFor="promo-progress-bar">Promotion Carousel Progress Bar</ProgressLabel>
              <ProgressBar id="promo-progress-bar" value={progressValue} max="100" />
            </StatusWrapper>
          </>
        )
      }
    </Promo>
  );
};

PromoCopy.defaultProps = {
  activePromo: 0,
  interval: 6000,
  isNavigationActive: false
};

PromoCopy.propTypes = {
  activePromo: PropTypes.number,
  content: PropTypes.array.isRequired,
  interval: PropTypes.number,
  isNavigationActive: PropTypes.bool
};

PromoCopy.whyDidYouRender = true;

export default memo(PromoCopy);
